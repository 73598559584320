import { Title } from '@solidjs/meta';

export default function Components() {
	return (
		<>
			<Title>Forms | Styleguide | Troon</Title>
			<h1 class="mb-8 text-4xl font-semibold">Forms</h1>

			<div class="mb-8 grid grid-cols-12 gap-4" />
		</>
	);
}
